import { useEffect, useRef } from 'react'

export const usePrevious = value => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  }, [value])
  return ref.current
}

export function getOffset(element)
{
  var offsetLeft = 0
  var offsetTop = 0

  while (element)
  {
    offsetLeft += element.offsetLeft
    offsetTop += element.offsetTop

    element = element.offsetParent
  }

  return { offsetLeft, offsetTop }
}
