import { useCallback, useEffect, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Box, Button, Fade, MobileStepper, ToggleButton, ToggleButtonGroup, useTheme } from '@mui/material'
import { ArrowBack, ArrowForward, HighlightOff, RestartAlt, Save } from '@mui/icons-material'

import SwipeableViews from 'react-swipeable-views'
import Flags from 'country-flag-icons/react/3x2'

import { GermanKeyboard, IdleTimer, Snackbar } from 'components/basicUi'
import { IdleDialog, SaveSurveyDialog } from 'components/dialogs'
import { usePrevious } from 'util'

import {
  SlideOne,
  SlideTwo,
  SlideThree,
  SlideFour,
  SlideFive,
  SlideSix,
  SlideSeven,
  SlideEight,
} from 'components/slides'

const slides = [
  SlideOne,
  SlideTwo,
  SlideThree,
  SlideFour,
  SlideFive,
  SlideSix,
  SlideSeven,
  SlideEight,
]
const STEP_COUNT = slides.length

const Layout = () => {
  const { i18n, t } = useTranslation()
  const theme = useTheme()
  const slidesRef = useRef(Array(slides.length))
  const keyboardRef = useRef(null)
  const finishedTimerRef = useRef(null)

  /** State management */
  const [activeSnackbar, setActiveSnackbar] = useState({})
  const [activeStep, setActiveStep] = useState(0)
  const prevActiveStep = usePrevious(activeStep)
  const [isImageSelectionSuccessHintAvailable, setIsImageSelectionSuccessHintAvailable] = useState(true)
  const [isKeyboardVisible, setIsKeyboardVisible] = useState(false)
  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false)
  const [keyboardInputCallback, setKeyboardInputCallback] = useState(null)
  const [keyboardInputName, setKeyboardInputName] = useState('')
  /** End of State Management */

  /** Dialog states */
  const [isIdleDialogVisible, setIsIdleDialogVisible] = useState(false)
  const [isSaveSurveyDialogVisible, setIsSaveSurveyDialogVisible] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [isSurveyFinished, setIsSurveyFinished] = useState(false)
  /** End of dialog states */

  /** Handlers */
  const handleStepForwardFromSlideFive = useCallback(() => {
    if (!slidesRef.current[5].canMoveToNextSlide()) {
      setActiveStep(5)
      setActiveSnackbar({
        type: 'error',
        text: <Trans i18nKey='snackbar.slideError' context='noImageSelected'/>,
        title: t('snackbar.slideError', { context: 'noImageSelectedTitle' }),
      })
      setIsSnackbarVisible(true)
    } else {
      setActiveStep(6)
      if (isImageSelectionSuccessHintAvailable) {
        setActiveSnackbar({
          type: 'success',
          text: t('snackbar.slideSuccess', { context: 'slideSevenFirstVisit' }),
        })
        setIsSnackbarVisible(true)
        setIsImageSelectionSuccessHintAvailable(false)
      }
    }
  }, [isImageSelectionSuccessHintAvailable, t])

  const handleStepChange = useCallback((index, indexLatest) => {
    setActiveStep(index)
    setIsSnackbarVisible(false)
    slidesRef.current[indexLatest].blur?.()
  }, [])

  const handleTransitionEnd = useCallback(() => {
    if (activeStep === 6 && slidesRef.current[5].canMoveToNextSlide()) {
      slidesRef.current[5].fixImage()
    }
  }, [activeStep])

  const handleBack = useCallback(() => {
    setActiveStep(activeStep - 1)
    setIsSnackbarVisible(false)
  }, [activeStep])

  const handleForward = useCallback(() => {
    if (activeStep === 5) {
      handleStepForwardFromSlideFive()
    } else if (activeStep === 6 && !isSurveyFinished) {
      setIsSaveSurveyDialogVisible(true)
    } else if (activeStep !== 5) {
      setActiveStep(activeStep + 1)
      setIsSnackbarVisible(false)
    }
  }, [activeStep, handleStepForwardFromSlideFive, isSurveyFinished])

  const handleReset = useCallback(() => {
    slidesRef.current.forEach(s => { s.reset() })
    setIsIdleDialogVisible(false)
    setActiveStep(0)
    setIsSnackbarVisible(false)
    setIsSaving(false)
    setIsSurveyFinished(false)
    setIsImageSelectionSuccessHintAvailable(true)
    window.clearTimeout(finishedTimerRef.current)
  }, [])

  const showSnackbar = useCallback(snackbarOptions => {
    setActiveSnackbar(snackbarOptions)
    setIsSnackbarVisible(true)
  }, [])

  const handleIdle = useCallback(() => {
    setIsIdleDialogVisible(true)
  }, [])

  const handleContinue = useCallback(() => {
    setIsIdleDialogVisible(false)
    setIsSaveSurveyDialogVisible(false)
  }, [])

  const handleSnackbarClose = useCallback(() => {
    setIsSnackbarVisible(false)
  }, [])

  const showKeyboard = useCallback((inputId, keyboardInputCallback) => {
    setIsKeyboardVisible(true)
    setKeyboardInputName(inputId)
    setKeyboardInputCallback(() => keyboardInputCallback)
  }, [])

  const hideKeyboard = useCallback(() => {
    setIsKeyboardVisible(false)
    setKeyboardInputName('')
    setKeyboardInputCallback(() => {})
  }, [])

  const changeLanguage = useCallback(lng => {
    i18n.changeLanguage(lng)
  }, [i18n])

  const handleSave = useCallback(() => {
    setIsSaving(true)
    const timestamp = Date.now()
    const data = slidesRef.current
      .map(s => s.save())
      .filter(v => v !== undefined)
    data.push(timestamp)
    fetch('https://react.c-20.de/submit', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).finally(() => {
      window.setTimeout(() => {
        setIsSaving(false)
        setIsSurveyFinished(true)
      }, 1500)
    })
  }, [])
  /** End of Handlers */

  useEffect(() => {
    if (activeStep === 6 && prevActiveStep === 5) {
      handleStepForwardFromSlideFive()
    }
  }, [activeStep, handleStepForwardFromSlideFive, prevActiveStep])

  useEffect(() => {
    if (!isSurveyFinished && activeStep === 7) {
      setActiveStep(6)
      setIsSaveSurveyDialogVisible(true)
    }
  }, [activeStep, isSurveyFinished])

  useEffect(() => {
    if (isSurveyFinished) {
      setIsSaveSurveyDialogVisible(false)
      setActiveStep(7)
      finishedTimerRef.current = window.setTimeout(() => {
        handleReset()
      }, 5000)
    }
  }, [handleReset, isSurveyFinished])

  useEffect(() => {
    if (activeStep === 0) {
      hideKeyboard()
    }
  }, [activeStep, hideKeyboard])

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', width: '100vw' }}>
        <Box sx={{ flexGrow: 1 }}>
          <SwipeableViews
            index={activeStep}
            disabled={isSurveyFinished}
            onChangeIndex={handleStepChange}
            onTransitionEnd={handleTransitionEnd}
            enableMouseEvents
            containerStyle={{
              height: '100%',
            }}
            style={{
              height: '100%',
            }}
          >
            {slides.map((s, i) => {
              const SlideComponent = s

              return (
                <SlideComponent
                  key={s.name}
                  hideKeyboard={hideKeyboard}
                  isIdleDialogVisible={isIdleDialogVisible}
                  showKeyboard={(inputId, keyboardInputCallback) => { showKeyboard(inputId, keyboardInputCallback) }}
                  showSnackbar={showSnackbar}
                  updateKeyboardInputValue={currentValue => { keyboardRef.current.setInput(currentValue, keyboardInputName) }}
                  ref={el => { slidesRef.current[i] = el }}
                />
              )
            })}
          </SwipeableViews>
        </Box>
        <Box sx={{ display: 'flex', width: '100%', backgroundColor: theme.palette.primary.main, justifyContent: 'space-between', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center' }}>
          <MobileStepper
            steps={STEP_COUNT}
            sx={{ width: 480, backgroundColor: 'transparent' }}
            variant='dots'
            position='static'
            activeStep={activeStep}
            backButton={(
              <Button
                color='secondary'
                disabled={activeStep === 0 || activeStep === STEP_COUNT - 1}
                onClick={handleBack}
                startIcon={<ArrowBack/>}
                variant='outlined'
              >
                {t('ui.back')}
              </Button>
            )}
            nextButton={(
              <Button
                color={activeStep < STEP_COUNT - 2 ? 'secondary' : 'success'}
                disabled={activeStep === STEP_COUNT - 1}
                onClick={handleForward}
                startIcon={activeStep >= STEP_COUNT - 2 ? <Save/> : undefined}
                endIcon={activeStep < STEP_COUNT - 2 ? <ArrowForward/> : undefined}
                variant='outlined'
              >
                {activeStep < STEP_COUNT - 2 ? t('ui.forward') : t('ui.save')}
              </Button>
            )}
          />
          <ToggleButtonGroup size='medium' value={i18n.language}>
            <ToggleButton
              size='medium'
              sx={{ width: 50, height: 'auto' }}
              onClick={() => { changeLanguage('de') }}
              value='de'
            >
              <Flags.DE/>
            </ToggleButton>
            <ToggleButton
              size='medium'
              sx={{ width: 50, height: 'auto' }}
              onClick={() => { changeLanguage('en') }}
              value='en'
            >
              <Flags.US/>
            </ToggleButton>
          </ToggleButtonGroup>
          <Button
            color={isSurveyFinished ? 'success' : 'error'}
            disabled={activeStep === 0}
            onClick={handleReset}
            startIcon={isSurveyFinished ? <RestartAlt/> : <HighlightOff/>}
            sx={{ justifySelf: 'flex-end', ml: 1, mr: 1 }}
            variant={isSurveyFinished ? 'contained' : 'outlined'}
          >
            {isSurveyFinished ? t('ui.restart') : t('ui.cancel')}
          </Button>
        </Box>
      </Box>
      <Fade in={isKeyboardVisible} timeout={{ enter: 250, exit: 500 }}>
        <Box
          sx={{ position: 'absolute', bottom: theme.spacing(10), left: '50%', display: 'flex', flexDirection: 'row', justifyContent: 'center', width: 600 }}
          style={{ transform: 'translateX(-50%)' }}
        >
          <GermanKeyboard
            inputName={keyboardInputName}
            keyboardRef={keyboardRef}
            onChange={keyboardInputCallback}
          />
        </Box>
      </Fade>
      <IdleTimer
        isRunning={activeStep !== 0 && !isIdleDialogVisible && !isSaveSurveyDialogVisible && activeStep !== STEP_COUNT - 1}
        onIdle={handleIdle}
      />
      <IdleDialog
        onContinue={handleContinue}
        onReset={handleReset}
        onClose={handleContinue}
        open={isIdleDialogVisible}
      />
      <SaveSurveyDialog
        isSaving={isSaving}
        onSave={handleSave}
        onClose={handleContinue}
        open={isSaveSurveyDialogVisible}
      />
      <Snackbar
        open={!!activeSnackbar && isSnackbarVisible}
        onClose={handleSnackbarClose}
        {...activeSnackbar}
      />
    </>
  )
}

export default Layout
