import { forwardRef, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'
import { SlideWrapper } from 'components/basicUi'

import { logo_denkraum } from 'assets'

const SlideOne = forwardRef((props, ref) => {
  const { t } = useTranslation()

  useImperativeHandle(ref, () => ({
    reset: () => {},
    save: () => {},
  }))

  return (
    <SlideWrapper>
      <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', height: '100%', alignItems: 'left' }}>
        <Typography variant='h4' gutterBottom>{t('slides.slideOne.headline')}</Typography>
        <Typography>{t('slides.slideOne.content') }</Typography>
        <Box sx={{ position: 'absolute', bottom: 56 + 64 }}>
          <img width='304' height='192' src={logo_denkraum} alt='Denkraum' style={{ maxHeight: 150, objectFit: 'cover', width: 'auto', height: 'auto' }}/>
        </Box>
      </Box>
    </SlideWrapper>
  )
})

export default SlideOne
