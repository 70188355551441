import React from 'react'
import { styled } from '@mui/material/styles'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
} from '@mui/material'
import { Close } from '@mui/icons-material'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props}/>
})

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}))

const BootstrapDialogTitle = props => {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
        >
          <Close/>
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

const CustomDialog = ({
  actions,
  children,
  onClose,
  open,
  title,
}) => {

  return (
    <div>
      <BootstrapDialog
        onClose={onClose}
        aria-labelledby='customized-dialog-title'
        open={open}
        TransitionComponent={Transition}
        keepMounted
      >
        <BootstrapDialogTitle id='customized-dialog-title' onClose={onClose}>
          {title}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          {children}
        </DialogContent>
        <DialogActions>
          {actions}
        </DialogActions>
      </BootstrapDialog>
    </div>
  )
}

export default CustomDialog
