import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Keyboard from 'react-simple-keyboard'
import german from 'simple-keyboard-layouts/build/layouts/german'
import 'react-simple-keyboard/build/css/index.css'

const GermanKeyboard = ({
  inputName,
  onChange,
  keyboardRef,
}) => {
  const { t } = useTranslation()
  const [layoutName, setLayoutName] = useState('default')
  const [isCapsLocked, setIsCapsLocked] = useState(false)

  useEffect(() => {
    if (isCapsLocked) {
      setLayoutName('shift')
    } else {
      setLayoutName('default')
    }
  }, [isCapsLocked])

  const onKeyPress = useCallback(button => {
    // handle the shift and caps lock buttons
    if (button === '{shift}') {
      if (!isCapsLocked) {
        setLayoutName(layoutName === 'default' ? 'shift' : 'default')
      }
    } else if (button === '{lock}') {
      setIsCapsLocked(!isCapsLocked)
    } else {
      if (!isCapsLocked) { setLayoutName('default') }
    }
  }, [isCapsLocked, layoutName])

  const display = useMemo(() => {
    return t('keyboard')
  }, [t])

  return (
    <Keyboard
      display={display}
      excludeFromLayout={{
        default: ['.com', '´', '^'],
        shift: ['.com', '`', '°'],
      }}
      inputName={inputName}
      keyboardRef={ref => { keyboardRef.current = ref }}
      layout={german.layout}
      layoutName={layoutName}
      newLineOnEnter
      onKeyPress={onKeyPress}
      onChange={onChange}
      useTouchEvents
      preventMouseDownDefault
    />
  )
}

export default GermanKeyboard
