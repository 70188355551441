import { useCallback, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Box, Button, Typography, useTheme } from '@mui/material'
import { CustomDialog } from 'components/basicUi'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'

const WAIT_BEFORE_RESET = 30 // time to wait before reset is triggered in seconds

const IdleDialog = ({
  onClose,
  onContinue,
  onReset,
  open,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const timerKey = useRef(1)

  const handleClose = useCallback(() => {
    timerKey.current++
    onClose()
  }, [onClose])

  const handleContinue = useCallback(() => {
    timerKey.current++
    onContinue()
  }, [onContinue])

  const handleReset = useCallback(() => {
    timerKey.current++
    onReset()
  }, [onReset])

  return (
    <CustomDialog
      open={open}
      onClose={handleClose}
      title={t('idleDialog.title')}
      actions={(
        <>
          <Button color='secondary' onClick={handleContinue} variant='contained'>
            {t('ui.continue')}
          </Button>
          <Button color='error' onClick={handleReset} variant='contained'>
            {t('ui.cancel')}
          </Button>
        </>
      )}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Box sx={{ mr: 4 }}>
          <CountdownCircleTimer
            isPlaying={open}
            key={timerKey.current}
            duration={WAIT_BEFORE_RESET}
            colors={[theme.palette.warning.dark, theme.palette.error.dark]}
            colorsTime={[30, 0]}
            size={150}
            onComplete={() => {
              handleReset()
              return { shouldRepeat: false }
            }}
          >
            {({ remainingTime }) => <Typography variant='h3'>{remainingTime}</Typography>}
          </CountdownCircleTimer>
        </Box>
        <Box>
          <Typography variant='body2' gutterBottom>
            {t('idleDialog.content1')}
          </Typography>
          <Typography variant='body2' gutterBottom>
            <Trans i18nKey='idleDialog.content2'/>
          </Typography>
          <Typography variant='body2' gutterBottom>
            {t('idleDialog.content3')}
          </Typography>
        </Box>
      </Box>
    </CustomDialog>
  )
}

export default IdleDialog
