import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { css, Global } from '@emotion/react'
import * as ReactDOM from 'react-dom'
import { Trans, useTranslation } from 'react-i18next'
import { Box, Button, Fade, Typography, useTheme } from '@mui/material'
import { Check, Search } from '@mui/icons-material'

// eslint-disable-next-line import/no-unresolved
import PhotoSwipeLightbox from 'photoswipe/lightbox'
import PhotoSwipe from 'photoswipe'
import 'photoswipe/dist/photoswipe.css'

import { SlideWrapper } from 'components/basicUi'
import { image1, image2, image3, image1_thumb, image2_thumb, image3_thumb } from 'assets'

const images = [
  { original: image1, thumb: image1_thumb, width: 640, height: 409 },
  { original: image2, thumb: image2_thumb, width: 480, height: 480 },
  { original: image3, thumb: image3_thumb, width: 616, height: 480 },
]

const MAGINIFICATION_FACTOR = 1.4

const AppendToBody = ({
  children,
}) => {
  const el = useRef(document.createElement('div'))
  el.current.setAttribute('style', 'position: absolute; bottom: 40px; width: 100%; z-index: 999999')

  useEffect(() => {
    const current = el.current
    document.body.appendChild(current)

    return () => {
      document.body.removeChild(current)
    }
  }, [])
  return ReactDOM.createPortal(children, el.current)
}

const SlideSix = forwardRef(({
  isIdleDialogVisible,
}, ref) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const [isLightboxVisible, setIsLightboxVisible] = useState(false)
  const [currentSlideIndex, setCurrentSlideIndex] = useState(null)
  const [selectedImage, setSelectedImage] = useState(null)
  const [isImageFixedUntilReset, setIsImageFixedUntilReset] = useState(false)

  const styles = {
    linkElement: {
      textDecoration: 'none',
      position: 'relative',
    },
    searchIcon: {
      display: 'inline-block',
      position: 'absolute',
      right: 0,
      top: 200,
      transform: 'translateY(-100%)',
      color: theme.palette.text.secondary,
      width: 24,
      height: 24,
    },
    thumbnail: {
      cursor: 'pointer',
      objectFit: 'cover',
      width: 200,
      height: 200,
    },
    selectedImage: {
      outline: `${theme.spacing(0.5)} solid ${theme.palette.secondary.main}`,
    },
    singleImage: {
      width: '100%',
      height: 450,
      objectFit: 'cover',
    },
  }

  useImperativeHandle(ref, () => ({
    canMoveToNextSlide: () => {
      return selectedImage !== null
    },
    reset: () => {
      setIsImageFixedUntilReset(false)
      setSelectedImage(null)
    },
    fixImage: () => {
      setIsImageFixedUntilReset(true)
    },
    save: () => {
      return selectedImage
    },
  }))

  const lightboxRef = useRef(null)

  useEffect(() => {
    let lightbox = new PhotoSwipeLightbox({
      gallery: '#slideSix',
      children: 'a',
      pswpModule: PhotoSwipe,
    })
    lightbox.on('openingAnimationEnd', () => {
      setIsLightboxVisible(true)
    })
    lightbox.on('closingAnimationStart', () => {
      setIsLightboxVisible(false)
    })
    lightbox.on('change', () => {
      const { pswp: { currIndex } } = lightbox
      setCurrentSlideIndex(currIndex)
    })
    lightbox.on('uiRegister', function() {
      lightbox.pswp.ui.registerElement({
        name: 'custom-caption',
        order: 9,
        isButton: false,
        appendTo: 'root',
        html: '',
        onInit: (el, pswp) => {
          lightbox.pswp.on('change', () => {
            const currSlideElement = lightbox.pswp.currSlide.data.element
            el.innerHTML = currSlideElement?.querySelector('img').getAttribute('alt') || ''
          })
        },
      })
    })

    lightbox.init()
    lightboxRef.current = lightbox

    return () => {
      lightbox.destroy()
      lightbox = null
      lightboxRef.current = null
    }
  }, [])

  useEffect(() => {
    if (isIdleDialogVisible) {
      lightboxRef?.current.pswp?.close()
    }
  }, [isIdleDialogVisible])

  const { current: imageCaptions } = useRef(t('slides.slideSix.imageCaptions'))

  return (
    <>
      <Global styles={css`
        .pswp__custom-caption {
          position: absolute;
          top: 40px;
          left: 50%;
          transform: translateX(-50%);
          color: #FFFFFF;
        }
        .pswp--touch .pswp__button--arrow {
          visibility: visible !important;
        }
      `}
      />
      <SlideWrapper>
        <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', height: '100%', alignItems: 'left' }}>
          <Typography variant='h4' gutterBottom>{t('slides.slideSix.headline', { context: isImageFixedUntilReset ? 'fixed' : '' })}</Typography>
          <Typography>
            <Trans i18nKey='slides.slideSix.content' context={isImageFixedUntilReset ? 'fixed' : ''}/>
          </Typography>
          <div
            id='slideSix'
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: isImageFixedUntilReset ? 'center' : 'space-between',
              marginTop: theme.spacing(2),
            }}
          >
            {!isImageFixedUntilReset
              ? images.map((i, k) => (
                <a
                  href={i.original}
                  data-pswp-width={i.width * MAGINIFICATION_FACTOR}
                  data-pswp-height={i.height * MAGINIFICATION_FACTOR}
                  key={i.original}
                  target='_blank'
                  rel='noreferrer'
                  style={{ ...styles.linkElement }}
                >
                  <img src={i.thumb} alt={imageCaptions[k]} style={{ ...styles.thumbnail, ...(selectedImage === k) && styles.selectedImage }}/>
                  <div style={styles.searchIcon}><Search/></div>
                  <Typography color='text.secondary' display='block' mt={0.5} variant='body2'>
                    {imageCaptions[k]}
                  </Typography>
                </a>
              ))
              : (
                <a
                  href={images[selectedImage].original}
                  data-pswp-width={images[selectedImage].width * MAGINIFICATION_FACTOR}
                  data-pswp-height={images[selectedImage].height * MAGINIFICATION_FACTOR}
                  key={images[selectedImage].original}
                  target='_blank'
                  rel='noreferrer'
                  style={{ ...styles.linkElement }}
                >
                  <img src={images[selectedImage].original} alt={imageCaptions[selectedImage]} style={{ ...styles.singleImage }}/>
                  <Typography align='center' color='text.secondary' display='block' mt={0.5} variant='body2'>
                    {imageCaptions[selectedImage]}
                  </Typography>
                </a>
              )
            }
          </div>
        </Box>
        <AppendToBody>
          <Fade in={isLightboxVisible && !isImageFixedUntilReset}>
            <Box
              style={{ transform: 'translateX(-50%)' }}
              sx={{ position: 'absolute', bottom: theme.spacing(4), left: '50%' }}
            >
              <Button
                color='secondary'
                startIcon={<Check/>}
                size='large'
                variant='contained'
                onClick={() => {
                  setSelectedImage(currentSlideIndex)
                  lightboxRef?.current.pswp.close()
                }}
              >
                {t('slides.slideSix.chooseImage')}
              </Button>
            </Box>
          </Fade>
        </AppendToBody>
      </SlideWrapper>
    </>
  )
})

export default SlideSix
