import { useEffect, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { Duration } from 'luxon'
import { Box, Button, Fade } from '@mui/material'
import { AlarmSharp } from '@mui/icons-material'

import { usePrevious } from 'util'

const IDLE_TIMEOUT = 1000 * 60 * 2 + 999 // 2 minutes and 999 milliseconds in milliseconds

const IdleTimerClock = ({
  getRemainingTime,
}) => {
  const [formattedRemainingTime, setFormattedRemainingTime] = useState(Duration.fromMillis(IDLE_TIMEOUT).toFormat('mm:ss'))

  useEffect(() => {
    const idleTimerRef = window.setInterval(() => {
      setFormattedRemainingTime(Duration.fromMillis(getRemainingTime() || IDLE_TIMEOUT).toFormat('mm:ss'))
    }, 1000)
    return () => {
      window.clearTimeout(idleTimerRef)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Button color='warning' startIcon={<AlarmSharp/>} variant='text'>{formattedRemainingTime}</Button>
  )
}

const IdleTimer = ({
  isRunning,
  onIdle,
}) => {
  const { getRemainingTime, start, pause } = useIdleTimer({
    eventsThrottle: 500,
    onIdle,
    startManually: true,
    stopOnIdle: true,
    throttle: 500,
    timeout: IDLE_TIMEOUT,
  })

  const prevIsRunning = usePrevious(isRunning)

  useEffect(() => {
    if (!prevIsRunning && isRunning) {
      start()
    } else if (prevIsRunning && !isRunning) {
      pause()
    }
  }, [isRunning, pause, prevIsRunning, start])

  const [isIdleTimerClockVisible, setIsIdleTimerClockVisible] = useState(false)

  return (
    <Box
      onClick={() => { setIsIdleTimerClockVisible(!isIdleTimerClockVisible) }}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', right: 0, top: 0, width: 150, height: 75 }}
    >
      <Fade in={isIdleTimerClockVisible}>
        <div>
          {isIdleTimerClockVisible && (
            <IdleTimerClock getRemainingTime={getRemainingTime}/>
          )}
        </div>
      </Fade>
    </Box>
  )
}

export default IdleTimer
