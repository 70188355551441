import { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, TextField, Typography } from '@mui/material'
import { SlideWrapper } from 'components/basicUi'

const SlideFour = forwardRef(({
  hideKeyboard,
  showKeyboard,
  updateKeyboardInputValue,
}, ref) => {
  const { t } = useTranslation()
  const [inputValues, setInputValues] = useState({})
  const options = Object.entries(t('slides.slideFour.inputs'))
  const textFieldsRef = useRef(Array(options.length))

  useImperativeHandle(ref, () => ({
    blur: () => {
      textFieldsRef.current.forEach(tf => { tf.blur() })
    },
    reset: () => {
      setInputValues({})
    },
    save: () => {
      // returns only text if there is no more than one input
      return (options.length === 1 ? inputValues[options[0][0]] : inputValues) ?? ''
    },
  }))

  return (
    <SlideWrapper>
      <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', height: '100%', alignItems: 'left' }}>
        <Typography variant='h4' gutterBottom>{t('slides.slideFour.headline')}</Typography>
        <Typography>{t('slides.slideFour.content')}</Typography>
        {options.map(([key, value], i) => {
          const onChange = event => {
            setInputValues({ ...inputValues, [key]: event.target.value })
            updateKeyboardInputValue(event.target.value)
          }
          const onVirtualKeyboardInputChange = input => {
            setInputValues({ ...inputValues, [key]: input })
          }

          return (
            <TextField
              color='secondary'
              fullWidth
              id={key}
              inputRef={tf => { textFieldsRef.current[i] = tf }}
              key={key}
              label={value}
              multiline
              onBlur={hideKeyboard}
              onChange={onChange}
              onFocus={() => { showKeyboard(key, onVirtualKeyboardInputChange) }}
              rows={5}
              sx={{ marginBottom: 2, marginTop: 2 }}
              value={inputValues[key] ?? ''}
              variant='outlined'
            />
          )
        })}
      </Box>
    </SlideWrapper>
  )
})

export default SlideFour
