import { forwardRef, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'
import { SlideWrapper } from 'components/basicUi'

import { logo_denkraum } from 'assets'

const SlideEight = forwardRef((props, ref) => {
  const { t } = useTranslation()

  useImperativeHandle(ref, () => ({
    reset: () => {},
    save: () => {},
  }))

  return (
    <SlideWrapper>
      <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', height: '100%', alignItems: 'center' }}>
        <Box sx={{ marginTop: '158px' }}>
          <Typography variant='h3'>{t('slides.slideEight.headline')}</Typography>
          <Typography>{t('slides.slideEight.content')}</Typography>
          <Box sx={{ marginTop: 8 }}>
            <img width='304' height='192' src={logo_denkraum} alt='Denkraum' style={{ maxHeight: 150, objectFit: 'cover', width: 'auto', height: 'auto' }}/>
          </Box>
        </Box>
      </Box>
    </SlideWrapper>
  )
})

export default SlideEight
