import { forwardRef, useCallback, useImperativeHandle, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Checkbox, Fab, FormControlLabel, Typography } from '@mui/material'
import { CircleOutlined, CheckCircleOutlined, DeleteForever } from '@mui/icons-material'
import { SlideWrapper } from 'components/basicUi'

const SlideTwo = forwardRef(({
  showSnackbar,
}, ref) => {
  const { t } = useTranslation()
  const [selection, setSelection] = useState([])
  const options = useMemo(() => Object.entries(t('slides.slideTwo.options')), [t])
  const settings = useMemo(() => t('slides.slideTwo.settings'), [t])

  useImperativeHandle(ref, () => ({
    reset: () => {
      setSelection([])
    },
    save: () => {
      return selection
    },
  }))

  const handleChange = useCallback(event => {
    const { value } = event.target

    let nextSelection = []
    if (selection.includes(value)) {
      nextSelection = selection.filter(e => !(e === value))
    } else {
      if (selection.length < settings.selectMaxLimit) {
        nextSelection = [value, ...selection]
      } else {
        nextSelection = selection
        showSnackbar({
          type: 'info',
          text: t('snackbar.slideError', { context: 'selectMaxLimit', selectMaxLimit: settings.selectMaxLimit }),
        })
      }
    }
    setSelection(nextSelection)
  }, [selection, settings.selectMaxLimit, showSnackbar, t])

  return (
    <SlideWrapper>
      <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', height: '100%', alignItems: 'left' }}>
        <Typography variant='h4' gutterBottom>{t('slides.slideTwo.headline')}</Typography>
        <Typography variant='h5' gutterBottom>{t('slides.slideTwo.subHeadline')}</Typography>
        <Typography>{t('slides.slideTwo.content')}</Typography>
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'row', flexWrap: 'wrap', position: 'relative', mt: 2 }}>
          {options.map(([key, value]) => (
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!selection?.includes(key)}
                  color='secondary'
                  checkedIcon={<CheckCircleOutlined/>}
                  icon={<CircleOutlined/>}
                  onChange={handleChange}
                  value={key}
                />
              }
              key={key}
              label={value}
              sx={{ width: 'calc(50% - 5px)' }}
            />
          ))}
          <Box sx={{ position: 'absolute', left: -100, top: 'calc(50% - 38px)', width: 75, height: 75 }}>
            <Fab color='secondary' disabled={selection.length === 0} onClick={() => { setSelection([]) }}>
              <DeleteForever/>
            </Fab>
          </Box>
        </Box>
      </Box>
    </SlideWrapper>
  )
})

export default SlideTwo
