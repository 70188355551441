import React from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, AlertTitle, Slide, Snackbar as MuiSnackbar } from '@mui/material'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide {...props} direction='right' ref={ref}/>
})

const Snackbar = ({
  onClose,
  open,
  text,
  title,
  type,
}) => {
  const { t } = useTranslation()

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    } else {
      onClose()
    }
  }

  return (
    <MuiSnackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      TransitionComponent={Transition}
    >
      <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
        <AlertTitle>{title || t('snackbar.typeTitle', { context: type })}</AlertTitle>
        {text}
      </Alert>
    </MuiSnackbar>
  )
}

export default Snackbar
