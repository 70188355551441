import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { CustomDialog } from 'components/basicUi'
import { Save } from '@mui/icons-material'

const SaveSurveyDialog = ({
  isSaving,
  onClose,
  onSave,
  open,
}) => {
  const { t } = useTranslation()

  const handleClose = useCallback(() => {
    onClose()
  }, [onClose])

  const handleSave = useCallback(() => {
    onSave()
  }, [onSave])

  return (
    <CustomDialog
      open={open || isSaving}
      onClose={handleClose}
      title={t('saveSurveyDialog.title')}
      actions={(
        <>
          <LoadingButton
            loading={isSaving}
            color='success'
            onClick={handleSave}
            variant='contained'
            startIcon={<Save/>}
          >
            {t('ui.save')}
          </LoadingButton>
        </>
      )}
    >
      <Typography variant='body2' gutterBottom>
        {t('saveSurveyDialog.content')}
      </Typography>
    </CustomDialog>
  )
}

export default SaveSurveyDialog
