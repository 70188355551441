import { forwardRef, Fragment, useImperativeHandle, useLayoutEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Grow, Radio, RadioGroup, TextField, Typography } from '@mui/material'
import { CircleOutlined, CheckCircleOutlined, HighlightOff } from '@mui/icons-material'
import { SlideWrapper } from 'components/basicUi'
import { getOffset } from 'util'

const SlideSeven = forwardRef(({
  hideKeyboard,
  showKeyboard,
  updateKeyboardInputValue,
}, ref) => {
  const { t } = useTranslation()
  const [selection, setSelection] = useState({})
  const [translateY, setTranslateY] = useState(0)

  const questions = Object.entries(t('slides.slideSeven.questionsWithComment'))

  const handleRadioChange = event => { setSelection({ ...selection, ...JSON.parse(event.target.value) }) }

  const handleTextFieldChange = event => {
    const { id, value: text } = event.target
    const key = id.split('_')[0]
    const { answer } = selection[key]
    setSelection({ ...selection, [key]: { answer, text } })
    updateKeyboardInputValue(event.target.value)
  }

  const textFieldsRef = useRef(Array(questions.length))
  useImperativeHandle(ref, () => ({
    blur: () => {
      textFieldsRef.current.forEach(tf => { tf.blur() })
    },
    reset: () => {
      setSelection({})
      setTranslateY(0)
    },
    save: () => {
      return selection
    },
  }))

  const keyboardRef = useRef(document.getElementsByClassName('react-simple-keyboard')[0])
  const [keyboardDimensions, setKeyboardDimensions] = useState({})
  useLayoutEffect(() => {
    setKeyboardDimensions(keyboardRef.current.getBoundingClientRect().toJSON())
  }, [])

  return (
    <SlideWrapper>
      <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', height: 'calc(100vh - 56px)', alignItems: 'left' }}>
        <Box id='slideSeven' sx={{ transform: `translateY(${translateY}px)`, transition: 'transform 500ms' }}>
          <Typography variant='h4' gutterBottom>{t('slides.slideSeven.headline')}</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Typography variant='body1' sx={{ width: 42 }} align='center'>{t('slides.slideSeven.answerKeys.yes')}</Typography>
            <Typography variant='body1' sx={{ width: 42 }} align='center'>{t('slides.slideSeven.answerKeys.no')}</Typography>
          </Box>
          {questions.map(([questionKey, questionConfig], i) => {
            const answers = Object.entries(questionConfig.answers)
            const currentAnswer = selection[questionKey]?.answer
            const currentTextValue = selection[questionKey]?.text
            const textFieldLabel = currentAnswer && t(`slides.slideSeven.questionsWithComment.${questionKey}.answers.${currentAnswer}`)

            const onVirtualKeyboardInputChange = input => {
              const { answer } = selection[questionKey]
              setSelection({ ...selection, [questionKey]: { answer, text: input } })
            }

            return (
              <Fragment key={questionKey}>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                  <RadioGroup onChange={handleRadioChange} row>
                    {answers.map(([answerKey]) => (
                      <Radio
                        checked={selection[questionKey]?.answer === answerKey}
                        color='secondary'
                        checkedIcon={answerKey === 'yes' ? <CheckCircleOutlined/> : <HighlightOff/>}
                        icon={<CircleOutlined/>}
                        id={`${answerKey}_radio`}
                        name={answerKey}
                        key={answerKey}
                        value={JSON.stringify({ [questionKey]: { answer: answerKey } })}
                        sx={{ display: 'inline-flex', alignSelf: 'top' }}
                      />
                    ))}
                  </RadioGroup>
                  <Typography
                    component='div'
                    sx={{ display: 'inline-flex', flexDirection: 'row', alignItems: 'center' }}
                    variant='body1'
                  >
                    {questionConfig.text}
                  </Typography>
                </Box>
                <Grow in={!!textFieldLabel} timeout={500}>
                  <TextField
                    color='secondary'
                    fullWidth
                    id={`${questionKey}_text`}
                    inputRef={tf => { textFieldsRef.current[i] = tf }}
                    label={textFieldLabel}
                    onBlur={() => {
                      setTranslateY(0)
                      hideKeyboard()
                    }}
                    onChange={handleTextFieldChange}
                    onFocus={event => {
                      const { top: keyboardTop } = keyboardDimensions
                      const { offsetTop } = getOffset(event.target)
                      const { height } = event.target.getBoundingClientRect()
                      const bottom = offsetTop + height
                      if (bottom > keyboardTop - 20) {
                        setTranslateY(keyboardTop - 20 - bottom)
                      } else {
                        setTranslateY(0)
                      }
                      showKeyboard(event.target.id, onVirtualKeyboardInputChange)
                    }}
                    value={currentTextValue ?? ''}
                    sx={{ display: !textFieldLabel ? 'none' : 'block', marginLeft: '84px', width: 'calc(100% - 84px)', mb: 2 }}
                    variant='outlined'
                  />
                </Grow>
              </Fragment>
            )
          })}
        </Box>
      </Box>
    </SlideWrapper>
  )
})

export default SlideSeven
