import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import commonDe from 'locales/common_de.json'
import commonEn from 'locales/common_en.json'

const resources = {
  de: {
    common: {
      ...commonDe,
    },
  },
  en: {
    common: {
      ...commonEn,
    },
  },
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: 'de',
    debug: process.env.NODE_ENV === 'development',
    ns: ['common'],
    defaultNs: 'common',
    supportedLngs: ['de', 'en'],
    lng: 'de',
    resources,
    returnObjects: true,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
