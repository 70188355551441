import { Box } from '@mui/material'

const SlideWrapper = ({
  children,
}) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'flex-end', width: '60%', paddingLeft: '20%', paddingRight: '20%' }}>
      {children}
    </Box>
  )
}

export default SlideWrapper
