import 'App.css'
import 'i18n'

import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

import { createTheme, ThemeProvider } from '@mui/material'

import { Layout } from 'components/layout'

const theme = createTheme({
  components: {
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          pointerEvents: 'auto',
        },
      },
    },
    MuiMobileStepper: {
      styleOverrides: {
        dotActive: {
          backgroundColor: '#1565c0',
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        anchorOriginBottomLeft: {
          marginBottom: '36px',
        },
        root: {
          width: '420px',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          pointerEvents: 'none',
          userSelect: 'none',
        },
      },
    },
  },
  palette: {
    primary: {
      light: '#ffffff',
      main: '#f0f0f0',
      dark: '#aeaeae',
      contrastText: '#000',
    },
    secondary: {
      light: '#5e92f3',
      main: '#1565c0',
      dark: '#003c8f',
      contrastText: '#fff',
    },
  },
  transitions: {
    duration: {
      enteringScreen: 1000,
      leavingScreen: 500,
    },
  },
})


function App() {

  return (
    <div className='raumx'>
      <ThemeProvider theme={theme}>
        <Layout/>
      </ThemeProvider>
    </div>
  )
}

export default App
